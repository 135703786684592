<template>
  <div class="notice">
        <div class="cont">
            <van-row class="top" @click="getInfo('0')">
              <van-col :span="6">
                <img src="@/assets/img/task_icon.png" alt="" />
              </van-col>
              <van-col :span="18">
                <h3>走访任务</h3>
                <h4>Visit mission</h4>
              </van-col>
            </van-row>
            <van-row class="top patrol" @click="getInfo('1')">
              <van-col :span="6">
                <img src="@/assets/img/patrol_icon.png" alt="" />
              </van-col>
              <van-col :span="18">
                <h3>巡查任务</h3>
                <h4>Inspection Task</h4>
              </van-col>
            </van-row>
          <van-row class="top activityTask" @click="getInfo('2')">
            <van-col :span="6">
              <img src="@/assets/img/activityTask_icon.png" alt="" />
            </van-col>
            <van-col :span="18">
              <h3>活动任务</h3>
              <h4>Activity Task</h4>
            </van-col>
          </van-row>
          <van-row class="top other" @click="getInfo('3')">
            <van-col :span="6">
              <img src="@/assets/img/other_icon.png" alt="" />
            </van-col>
            <van-col :span="18">
              <h3>其他任务</h3>
              <h4>Other Tasks</h4>
            </van-col>
          </van-row>
        </div>
  </div>
</template>

<script>
export default {
     data() {
        return {
        searchValue: '',
        loading: false,
        finished: false,
        limit: 10,
        page: 0,
        dataList: [],
        };
     },
    methods: {
        getInfo (type) {
          if (type === '0') {
            this.$router.push({path: '/taskList'})
          } else if (type === '1') {
            this.$router.push({path: '/patrolList'})
          } else if (type === '2') {
            this.$router.push({path: '/activityList'})
          } else if (type === '3') {
            this.$router.push({path: '/otherList'})
          }
        },
    },
    created () {
    }
}
</script>

<style lang="scss" scoped>
.notice {
    //padding-top: 100px;
    .cont {
        //padding: 20px;
        background-color: #f5f5f5;
        .top {
            position: relative;
            height: 240px;
            border-radius: 20px;
            background-image: url("../../assets/img/task.png");
            background-size: 100%;
            background-repeat: no-repeat;
          h3 {
            font-size: 33px;
            font-weight: 400;
            font-family: PingFang-SC-Bold;
            color: #fff;
            margin-top: 57px;
            margin-bottom: 20px;
          }
          h4 {
            color: #fff;
            //opacity: 0.4;
            margin-top: 0;
            font-size: 28px;
          }
          .line {
            width: 50px;
            height: 8px;
            margin-top: 60px;
            border-radius: 4px;
            background-color: #fff;
          }
          img {
            position: absolute;
            top: 57px;
            left: 60px;
            width: 80px;
            height: 95px;
          }
        }
      .patrol {
        background-image: url("../../assets/img/patrol.png");
      }
      .activityTask {
        background-image: url("../../assets/img/activityTask.png");
      }
      .other {
        background-image: url("../../assets/img/other.png");
      }
    }
}
</style>
